import { FunctionComponent } from "react";
import { Select, selectClasses, outlinedInputClasses, MenuItem } from "@mui/material";

import { ScoringSeason } from "../../scoring/models";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appConfigActions } from "../../slices/appConfigSlice";

export const CompSeasonSelect: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const compSeasonId = useAppSelector((state) => state.appConfig.aflIds.compSeason.providerId);

  return (
    <Select
      size="small"
      color="secondary"
      displayEmpty
      value={compSeasonId}
      onChange={(e) => dispatch(appConfigActions.setCompSeason(e.target.value as ScoringSeason))}
      sx={{
        color: "currentcolor",
        [`.${selectClasses.icon}`]: {
          color: "currentcolor"
        },
        [`.${outlinedInputClasses.input}`]: {
          color: "currentcolor"
        },
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "currentcolor"
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "currentcolor"
        }
      }}
    >
      <MenuItem disabled value="">
        <em>Select compSeasonId</em>
      </MenuItem>
      {Object.values(ScoringSeason).map((id) => (
        <MenuItem key={id} value={id}>
          {id}
        </MenuItem>
      ))}
    </Select>
  );
};
