import { useFirestore } from "reactfire";

import { aflPlayersSelectors, useGetPlayerDetailsQuery } from "../slices/aflPlayersApi";
import { DropFirst } from "../utils/utilityTypes";

export const usePlayerDetails = (compSeasonId: string) =>
  useGetPlayerDetailsQuery(
    { firestore: useFirestore(), compSeasonId },
    {
      selectFromResult: (result) => ({
        ...result,
        selectAll: aflPlayersSelectors.selectAll(result),
        selectById: (...[playerId]: DropFirst<Parameters<typeof aflPlayersSelectors.selectById>>) =>
          aflPlayersSelectors.selectById(result, playerId),
        selectByIds: (...[playerIds]: DropFirst<Parameters<typeof aflPlayersSelectors.selectByIds>>) =>
          aflPlayersSelectors.selectByIds(result, playerIds)
      })
    }
  );
