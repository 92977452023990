import { createEntityAdapter } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";

import { UfflUser } from "../models/uffl";
import { ufflUserConverter } from "../models/firestoreDataConverters";
import { InferSelectFromResult, selectByIdFactory } from "../redux/selectorFactories";

import { firestoreApi, firestoreBaseCollection } from "./firestoreApi";

const ufflUsersAdapter = createEntityAdapter({
  selectId: (e: UfflUser) => e.uid,
  sortComparer: (a, b) => a.teamName.localeCompare(b.teamName)
});

const initialState = ufflUsersAdapter.getInitialState();

export const ufflUsersApi = firestoreApi.injectEndpoints({
  endpoints: (build) => ({
    getUfflUsers: build.query<
      ReturnType<typeof ufflUsersAdapter["getInitialState"]>,
      Firestore // TODO: temporary, prefer for this to be in the base query or just set in a single place
    >({
      // TODO: baseQuery returns baseCollection, transformResult does this?
      queryFn: () => {
        return { data: initialState };
      },
      async onCacheEntryAdded(firestore, { cacheEntryRemoved, getState, updateCachedData }) {
        let unsubscribe;
        try {
          const ref = collection(firestoreBaseCollection(firestore, getState), "app", "users").withConverter(
            ufflUserConverter
          );
          unsubscribe = onSnapshot(ref, {
            next: (snapshot) => {
              updateCachedData((draft) => {
                const docs = snapshot?.docs.map((s) => s.data()) ?? [];
                // console.log("getUfflUsers updateCachedData", { draft, snapshot, docs: docs });
                ufflUsersAdapter.setAll(draft, docs);
              });
            },
            error: (error) => {
              console.log("Firestore error: getUfflUsers", { error });
              // TODO: update cached data with error
            }
          });
        } catch (error) {
          console.log("Uncaught error: getUfflUsers", { error });
          throw new Error("Something went wrong with getUfflUsers.");
          // TODO: update cached data with error?
        }

        await cacheEntryRemoved;
        return unsubscribe();
      }
    })
  })
});

export const { useGetUfflUsersQuery } = ufflUsersApi;

const { selectById: selectByKnownId, ...selectors } = ufflUsersAdapter.getSelectors(
  (state: InferSelectFromResult<typeof useGetUfflUsersQuery>) => state?.data ?? initialState
);

export const ufflUsersSelectors = {
  ...selectors,
  selectById: selectByIdFactory(selectByKnownId)
};
