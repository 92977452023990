import { get, sum } from "lodash";
import { useMemo } from "react";
import { useFirestore } from "reactfire";

import { PlayerStatsFull } from "../models/player";
import { UfflStartingPosition } from "../models/uffl";
import { useAppSelector } from "../redux/hooks";
import { aflRoundPlayerStatsSelectors, useGetAflRoundPlayerStatsQuery } from "../slices/aflRoundPlayerStatsApi";
import { scoringRulesSelectors } from "../slices/scoringRulesSlice";
import { ufflRoundTeamsSelectors, useGetUfflRoundTeamsQuery } from "../slices/ufflRoundTeamsApi";
import { DropFirst } from "../utils/utilityTypes";

import { PositionWeighting, ScoringSeason } from "./models";

const getPositionScore = (
  weightings: PositionWeighting | undefined,
  stats: PlayerStatsFull | undefined
): number | undefined => {
  if (!weightings || !stats) {
    return undefined;
  }

  return Object.entries(weightings).reduce((acc: number | undefined, [stat, weight]) => {
    const count = get(stats, stat);
    if (typeof count !== "number") {
      return acc;
    }

    const value = count * weight;
    return (acc ?? 0) + value;
  }, undefined);
};

export const useUfflRoundPlayerStats = (compSeasonId: string, roundId: string) => {
  const firestore = useFirestore();
  const args = useMemo(() => ({ firestore, compSeasonId, roundId }), [firestore, compSeasonId, roundId]);

  const { selectScoringPlayerId } = useGetUfflRoundTeamsQuery(args, {
    selectFromResult: (result) => ({
      ...result,
      selectScoringPlayerId: (
        ...[uid, position]: DropFirst<Parameters<typeof ufflRoundTeamsSelectors.selectScoringPlayerId>>
      ) => ufflRoundTeamsSelectors.selectScoringPlayerId(result, uid, position)
    })
  });

  const { selectStatsByPlayerId } = useGetAflRoundPlayerStatsQuery(args, {
    selectFromResult: (result) => ({
      ...result,
      selectStatsByPlayerId: (...[playerId]: DropFirst<Parameters<typeof aflRoundPlayerStatsSelectors.selectById>>) =>
        aflRoundPlayerStatsSelectors.selectById(result, playerId)
    })
  });

  const selectLineupPositionStats = (uid: string, position: UfflStartingPosition) =>
    selectStatsByPlayerId(selectScoringPlayerId(uid, position));

  const selectLineupPositionScore = (
    weightings: PositionWeighting | undefined,
    uid: string,
    position: UfflStartingPosition
  ) => getPositionScore(weightings, selectLineupPositionStats(uid, position));

  return {
    selectScoringPlayerId,
    selectLineupPositionStats,
    selectLineupPositionScore
  };
};

export const usePositionScore = (
  compSeasonId: ScoringSeason,
  roundId: string,
  uid: string,
  position: UfflStartingPosition
): number | undefined => {
  const weights = useAppSelector((state) => scoringRulesSelectors.selectByPosition(state, compSeasonId, position));
  // const playerStats = useUfflRoundPlayerStats(compSeasonId, roundId).selectLineupPositionStats(uid, position);
  // return getPositionScore(weights, playerStats);
  return useUfflRoundPlayerStats(compSeasonId, roundId).selectLineupPositionScore(weights, uid, position);
};

export const useTotalScore = (compSeasonId: ScoringSeason, roundId: string, uid: string): number | undefined => {
  const ruleset = useAppSelector((state) => scoringRulesSelectors.selectByCompSeasonId(state, compSeasonId));

  const {
    // selectLineupPositionStats,
    selectLineupPositionScore
  } = useUfflRoundPlayerStats(compSeasonId, roundId);

  const scores =
    ruleset?.position &&
    Object.entries(ruleset.position).map(([position, weights]) => {
      /*
      const playerStats = selectLineupPositionStats(uid, position as UfflStartingPosition);
      return getPositionScore(weights, playerStats);
      */
      return selectLineupPositionScore(weights, uid, position as UfflStartingPosition);
    });

  const total = sum(scores);
  return total;
};
