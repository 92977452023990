import { UnknownAction, Dispatch, ThunkDispatch } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { store, RootState, AppDispatch } from "./store";

export const useAppDispatch = (): ThunkDispatch<RootState, null, UnknownAction> &
  ThunkDispatch<RootState, undefined, UnknownAction> &
  Dispatch<UnknownAction> => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppState = (): RootState => store.getState();
