import { FunctionComponent, useCallback, useMemo, MouseEvent } from "react";
import { styled, TableRow, TableCell, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { find } from "lodash";

import { useCompSeasonId } from "../../hooks/useCompSeasonId";
import { usePlayerDetails } from "../../hooks/usePlayerDetails";
import { LineupPlayer, PlayerDisplayItem } from "../../models/player";
import { UfflPosition, UfflReservePosition, subsPositions, Lineup } from "../../models/uffl";

const Toggle = styled(ToggleButton)`
  text-transform: none;
`;

interface PositionDetails extends LineupPlayer, PlayerDisplayItem {
  position: UfflPosition;
}

const getExistingPick = (lineup: Lineup, position: UfflPosition) => lineup[position];

type SubstitutionToggleProps = {
  reservePosition: UfflReservePosition;
  lineup: Lineup;
  doChange: (action: { position: UfflPosition; substituted: boolean }) => void;
};
export const SubstitutionToggle: FunctionComponent<SubstitutionToggleProps> = ({
  reservePosition,
  lineup,
  doChange
}) => {
  const compSeasonId = useCompSeasonId();
  const { selectById } = usePlayerDetails(compSeasonId);

  const getPositionDetails = useCallback(
    (position: UfflPosition): PositionDetails => {
      const pick = getExistingPick(lineup, position);
      const displayName = selectById(pick.playerId)?.displayName ?? "Not found";
      return { ...pick, displayName, position };
    },
    [selectById, lineup]
  );

  const reservePositionDetails = useMemo(
    () => getPositionDetails(reservePosition),
    [reservePosition, getPositionDetails]
  );

  const startingPositionsDetails = useMemo(() => {
    const startingPositions = subsPositions[reservePosition];
    return startingPositions.map((pos) => getPositionDetails(pos));
  }, [reservePosition, getPositionDetails]);

  const value = useMemo(() => {
    return find(startingPositionsDetails, ["substituted", true]) ?? null;
  }, [startingPositionsDetails]);

  const onChange = useCallback(
    (event: MouseEvent<HTMLElement>, newValue: PositionDetails | null) => {
      if (value !== null) {
        doChange({
          position: value.position,
          substituted: false
        });
      }
      if (newValue == null) {
        doChange({
          position: reservePosition,
          substituted: false
        });
        return;
      }
      doChange({
        position: reservePosition,
        substituted: true
      });
      doChange({
        position: newValue.position,
        substituted: true
      });
    },
    [value, reservePosition, doChange]
  );

  return (
    <TableRow sx={{ "td, th": { border: 0 } }}>
      <TableCell align="center">{reservePositionDetails.position}</TableCell>
      <TableCell align="center">{reservePositionDetails.displayName}</TableCell>
      <TableCell align="center">
        <ToggleButtonGroup exclusive value={value} onChange={onChange} color="secondary" size="small">
          {startingPositionsDetails.map((pick) => (
            <Toggle value={pick} key={pick.playerId}>
              {pick.displayName} ({pick.position})
            </Toggle>
          ))}
        </ToggleButtonGroup>
      </TableCell>
    </TableRow>
  );
};
