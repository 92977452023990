import { createDraftSafeSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";
import { pick } from "lodash";

import { PlayerStat, PlayerStatsFull } from "../models/player";
import { playerStatsFullConverter } from "../models/firestoreDataConverters";
import { InferSelectFromResult, selectByIdFactory } from "../redux/selectorFactories";

import { firestoreApi, firestoreBaseCollection } from "./firestoreApi";
import { AppendParameters } from "../utils/utilityTypes";
import { createKeysResolver } from "../redux/keysResolver";

const aflRoundPlayerStatsAdapter = createEntityAdapter({
  selectId: (e: PlayerStatsFull) => e.playerId
});

const initialState = aflRoundPlayerStatsAdapter.getInitialState();

export const aflRoundPlayerStatsApi = firestoreApi.injectEndpoints({
  endpoints: (build) => ({
    getAflRoundPlayerStats: build.query<
      ReturnType<typeof aflRoundPlayerStatsAdapter["getInitialState"]>,
      { firestore: Firestore; compSeasonId: string; roundId: string } // TODO: temporary, prefer for this to be in the base query or just set in a single place
    >({
      queryFn: () => {
        return { data: initialState };
      },
      async onCacheEntryAdded({ firestore, compSeasonId, roundId }, { cacheEntryRemoved, getState, updateCachedData }) {
        let unsubscribe;
        try {
          const ref = collection(
            firestoreBaseCollection(firestore, getState),
            "afl",
            "compSeasons",
            compSeasonId,
            "rounds",
            roundId,
            "playerStats"
          ).withConverter(playerStatsFullConverter);
          unsubscribe = onSnapshot(ref, {
            next: (snapshot) => {
              updateCachedData((draft) => {
                const docs = snapshot?.docs.map((s) => s.data()) ?? [];
                // console.log("aflRoundPlayerStatsApi updateCachedData", { draft, snapshot, docs: docs });
                aflRoundPlayerStatsAdapter.setAll(draft, docs);
              });
            },
            error: (error) => {
              console.log("Firestore error: getAflRoundPlayerStats", { error });
              // TODO: update cached data with error
            }
          });
        } catch (error) {
          console.log("Uncaught error: getAflRoundPlayerStats", { error });
          throw new Error("Something went wrong with getAflRoundPlayerStats.");
          // TODO: update cached data with error?
        }

        await cacheEntryRemoved;
        return unsubscribe();
      }
    })
  })
});

export const { useGetAflRoundPlayerStatsQuery } = aflRoundPlayerStatsApi;

const { selectById: selectByKnownId, ...selectors } = aflRoundPlayerStatsAdapter.getSelectors(
  (state: InferSelectFromResult<typeof useGetAflRoundPlayerStatsQuery>) => state?.data ?? initialState
);

const selectById = selectByIdFactory(selectByKnownId);

const playerStatsKeyResolver = createKeysResolver<PlayerStat>();

export const aflRoundPlayerStatsSelectors = {
  ...selectors,
  selectById,
  selectStatById: createDraftSafeSelector(
    [
      selectById,
      (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...[state, id, stat]: AppendParameters<typeof selectById, (stat: PlayerStat) => unknown>
      ) => stat
    ],
    (playerStats, stat) => playerStats?.[stat]
  ),
  selectStatsById: createDraftSafeSelector(
    [
      selectById,
      (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...[state, id, stats]: AppendParameters<typeof selectById, (stats: PlayerStat[]) => unknown>
      ) => playerStatsKeyResolver(state, stats)
    ],
    (playerStats, stats) => playerStats && pick(playerStats, stats)
  )
};
