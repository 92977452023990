import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";

import { PlayerStatsGroup } from "../models/player";
import { AflRound } from "../models/round";
import { selectByIdFactory } from "../redux/selectorFactories";

interface PlayerStatsUpdateAction {
  roundId: string;
  playerStats: PlayerStatsGroup;
}

const adapter = createEntityAdapter({ selectId: (e: AflRound) => e.providerId });

const initialState = adapter.getInitialState();

const { setAll, upsertMany } = adapter;

const { selectById: selectByKnownId, selectAll } = adapter.getSelectors();

const selectById = selectByIdFactory(selectByKnownId);

// TODO: remove slice, substitute useAflRounds
export const aflRoundsSlice = createSlice({
  name: "aflRounds",
  reducerPath: "aflRounds",
  initialState: initialState,
  reducers: {
    overwriteRounds: setAll,
    updateRounds: upsertMany,
    updatePlayerStatsSingle: (state, action: PayloadAction<PlayerStatsUpdateAction>) => {
      const { roundId, playerStats } = action.payload;
      const playerStatsGroup: PlayerStatsGroup = {
        playerId: playerStats.playerId,
        player: playerStats.player,
        totals: playerStats.stats?.totals
      };
      const round = selectById(state, roundId);
      if (round) {
        // get the stats list for the round, create if not exists
        if (!round.playerStats) {
          round.playerStats = [];
        }
        const statsList = round.playerStats;
        // now go looking for the player in the stats list
        const playerIndex = statsList.findIndex((res) => res.playerId === playerStatsGroup.playerId);
        if (playerIndex !== -1) {
          // found the player, update the existing entry
          statsList[playerIndex] = merge(statsList[playerIndex], playerStatsGroup);
        } else {
          // didn't find the player, add a new entry
          statsList.push(playerStatsGroup);
        }
      } else {
        // didn't find the round, wtf
        console.log(
          `Couldn't update stats for playerId ${playerStatsGroup.playerId}: roundId ${roundId} not found in aflRoundsSlice.`
        );
      }
    }
  },
  selectors: { selectById, selectAll }
});

export const aflRoundsActions = aflRoundsSlice.actions;

export const aflRoundsSelectors = aflRoundsSlice.selectors;
