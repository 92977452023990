import { FunctionComponent, useMemo } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appConfigActions } from "../../slices/appConfigSlice";

const then = "2024-05-06T11:09:29.886+0000";

export const ClockToggle: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const doClock = useAppSelector((state) => state.appConfig.doClock);
  const utcNow = useAppSelector((state) => state.appConfig.utcNow);
  const isThen = useMemo(() => utcNow === then, [utcNow]);
  const value = useMemo(() => {
    const value = [];
    if (isThen) {
      value.push("isThen");
    }
    if (doClock) {
      value.push("doClock");
    }
    return value;
  }, [isThen, doClock]);

  return (
    <ToggleButtonGroup
      value={value}
      onChange={(e, v) => {
        switch (v.includes("isThen")) {
          case isThen:
            break;
          default:
            dispatch(isThen ? appConfigActions.autoSetUtcNow() : appConfigActions.setUtcNow(then));
            break;
        }
        switch (v.includes("doClock")) {
          case doClock:
            break;
          default:
            dispatch(appConfigActions.setDoClock(!doClock));
            break;
        }
      }}
    >
      <ToggleButton value="isThen" selected={isThen} disabled={doClock}>
        {isThen ? "then" : "now"}
      </ToggleButton>
      <ToggleButton value="doClock" selected={doClock}>
        {doClock ? "running" : "stopped"}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
