import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AflIdPair } from "../models/afl";
import { ScoringSeason } from "../scoring/models";
import { now } from "../utils/datetime";

const compSeasonIds: Record<ScoringSeason, AflIdPair<ScoringSeason>> = {
  [ScoringSeason.CD_S2022014]: { id: 43, providerId: ScoringSeason.CD_S2022014 },
  [ScoringSeason.CD_S2023014]: { id: 52, providerId: ScoringSeason.CD_S2023014 },
  [ScoringSeason.CD_S2024014]: { id: 62, providerId: ScoringSeason.CD_S2024014 },
  [ScoringSeason.CD_S2025014]: { id: 73, providerId: ScoringSeason.CD_S2025014 }
};

enum AflIdPairType {
  Competition = "competition",
  CompSeason = "compSeason"
}

export interface AppConfigState {
  firestoreBasePath: string;
  aflIds: Record<AflIdPairType, AflIdPair> & { [AflIdPairType.CompSeason]: AflIdPair<ScoringSeason> };
  utcNow: string;
  doClock: boolean;
  devMode: {
    enabled: boolean;
    count: number;
  };
}

const initialState: AppConfigState = {
  firestoreBasePath: "prod",
  aflIds: {
    [AflIdPairType.Competition]: { id: 1, providerId: "CD_C014" },
    [AflIdPairType.CompSeason]: compSeasonIds[ScoringSeason.CD_S2025014]
  },
  utcNow: now(),
  doClock: true,
  devMode: {
    enabled: false,
    count: 0
  }
};

export const appConfigSlice = createSlice({
  name: "appConfig",
  reducerPath: "appConfig",
  initialState,
  reducers: {
    setCompSeason: (state, action: PayloadAction<ScoringSeason>) => {
      state.aflIds.compSeason = { ...compSeasonIds[action.payload] };
    },
    setUtcNow: (state, action: PayloadAction<string>) => {
      if (!action?.payload) return;
      state.utcNow = action.payload;
    },
    autoSetUtcNow: (state) => {
      state.utcNow = now();
    },
    setDoClock: (state, action: PayloadAction<boolean>) => {
      state.doClock = action.payload;
    },
    incrementDevModeCount: (state) => {
      state.devMode.count += 1;
      if (state.devMode.enabled || state.devMode.count < 7) return;
      state.devMode.enabled = true;
    }
  },
  selectors: {
    selectFirestoreBasePath: (state) => state.firestoreBasePath,
    selectCompSeasonId: (state) => state.aflIds.compSeason.providerId
  }
});

export const appConfigActions = appConfigSlice.actions;

export const appConfigSelectors = {
  selectSliceState: appConfigSlice.selectSlice,
  ...appConfigSlice.selectors
};
