import { FunctionComponent, useEffect } from "react";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";

import { useAppDispatch } from "../redux/hooks";
import { useFirestoreBasePath } from "../hooks/useFirestoreBasePath";
import { useCompSeasonId } from "../hooks/useCompSeasonId";
import { aflRoundsActions } from "../slices/aflRoundsSlice";

export const ReactFireListener: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const compSeasonId = useCompSeasonId();
  const firestoreBasePath = useFirestoreBasePath();

  //
  const aflCompSeason = useFirestoreDocData(
    doc(useFirestore(), firestoreBasePath, "afl", "compSeasons", compSeasonId),
    { idField: "providerId" }
  );

  useEffect(() => {
    console.log("ReactFireListener", { aflCompSeason });
    if (aflCompSeason.data) {
      dispatch(aflRoundsActions.updateRounds(aflCompSeason.data.rounds));
    }
  }, [aflCompSeason, dispatch]);

  return null;
};
