import { createDraftSafeSelector as createDSS, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { UfflStartingPosition } from "../models/uffl";
import { GetStateFromSelectors, selectByIdFactory } from "../redux/selectorFactories";
import { PositionWeightedStats, ScoringRuleset, ScoringSeason } from "../scoring/models";
import { isEnumMember } from "../utils/objectUtils";
import { AppendParameters } from "../utils/utilityTypes";

// TODO: cbf, these are the 2024 rules
const weightings2024: PositionWeightedStats = {
  FBK: { marks: 2, onePercenters: 2 },
  HBK: { kicks: 1, marks: 2 },
  MD1: { disposals: 1 },
  MD2: { disposals: 1 },
  SPD: { freesAgainst: 5 },
  HFW: { behinds: 1, goals: 6, inside50s: 3, tacklesInside50: 6 },
  FFW: { behinds: 1, goals: 6, marks: 2, marksInside50: 1 }
};

const rulesets: {
  [S in ScoringSeason]: ScoringRuleset & { compSeasonId: S };
} = {
  // TODO: cbf, these are the 2024 rules
  [ScoringSeason.CD_S2022014]: { compSeasonId: ScoringSeason.CD_S2022014, position: weightings2024 },
  // TODO: cbf, these are the 2024 rules
  [ScoringSeason.CD_S2023014]: { compSeasonId: ScoringSeason.CD_S2023014, position: weightings2024 },
  [ScoringSeason.CD_S2024014]: { compSeasonId: ScoringSeason.CD_S2024014, position: weightings2024 },
  [ScoringSeason.CD_S2025014]: {
    compSeasonId: ScoringSeason.CD_S2025014,
    position: {
      FBK: { marks: 2, onePercenters: 2 },
      HBK: { kicks: 1, marks: 1, rebound50s: 1 },
      MD1: { totalClearances: 1, handballs: 1, tackles: 2 },
      MD2: { kicks: 1, inside50s: 3 },
      SPD: { freesAgainst: 3 },
      HFW: { behinds: 1, goals: 6, inside50s: 3, tacklesInside50: 6 },
      FFW: { behinds: 1, goals: 6, marks: 2, marksInside50: 1 }
    }
  }
};

const adapter = createEntityAdapter({
  selectId: (e: ScoringRuleset & { compSeasonId: ScoringSeason }) => e.compSeasonId
});

const initialState = adapter.getInitialState(undefined, rulesets);

const { selectById: selectByKnownId, ...selectors } = adapter.getSelectors();

const selectByCompSeasonId = selectByIdFactory(selectByKnownId);

export const scoringRulesSlice = createSlice({
  name: "scoringRules",
  reducerPath: "scoringRules",
  initialState: initialState,
  reducers: {},
  selectors: {
    ...selectors,
    selectByCompSeasonId
  }
});

const createDraftSafeSelector = createDSS.withTypes<GetStateFromSelectors<[typeof scoringRulesSlice.selectSlice]>>();

const selectByPosition = createDraftSafeSelector(
  [
    scoringRulesSlice.selectors.selectByCompSeasonId,
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...[state, id, position]: AppendParameters<
        typeof scoringRulesSlice.selectors.selectByCompSeasonId,
        (position: UfflStartingPosition) => unknown
      >
    ) => position
  ],
  (ruleset, position) => ruleset?.position?.[position]
);

const isPosition = (key: unknown): key is UfflStartingPosition => isEnumMember(UfflStartingPosition, key);

export const scoringRulesSelectors = {
  ...scoringRulesSlice.selectors,
  selectPositions: createDraftSafeSelector([scoringRulesSlice.selectors.selectByCompSeasonId], (ruleset) =>
    ruleset?.position ? Object.keys(ruleset.position).filter(isPosition) : []
  ),
  selectByPosition
};
