import { FirestoreDataConverter } from "firebase/firestore";
import _ from "lodash";
import { AflTeam, AflIdPair } from "./afl";
import { PlayerStatsGroup } from "./player";
import {
  Lineup,
  // PlayerTrade,
  UfflMatch
} from "./uffl";

export interface UfflUserRound {
  uid: string;
  club?: string;
  players?: string[];
  lineup?: Lineup;
  // trades?: PlayerTrade[];
}

// TODO: remove this
export const ufflUserRoundConverter: FirestoreDataConverter<UfflUserRound> = {
  toFirestore: (object) => _.mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => snapshot.data(options) as UfflUserRound
};

export interface Round extends AflIdPair {
  providerId: string;
  id: number;
  roundNumber: number;
  name: string;
  abbreviation: string;
  utcStartTime: string;
  utcEndTime: string;
  byes: AflTeam[];
  playerStats?: PlayerStatsGroup[];
}

export interface UfflRound extends Round {
  fixture?: UfflMatch[];
  teams?: UfflUserRound[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AflRound extends Round {}

export enum AflRoundStatus {
  UPCOMING = "UPCOMING",
  LIVE = "LIVE",
  COMPLETED = "COMPLETED",
  NO_MATCHES = "NO_MATCHES"
}
