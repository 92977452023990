import { FunctionComponent, MouseEvent, useCallback, useEffect, useState } from "react";
import { Box, Dialog, Typography, styled, useTheme } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appConfigActions } from "../../slices/appConfigSlice";
import { FontSelect } from "../dev/theming/FontSelect";
import { PaletteSelect } from "../dev/theming/PaletteSelect";
import { ThemeModeToggle } from "../dev/theming/ThemeModeToggle";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

export const ToolbarMessage: FunctionComponent = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const devModeEnabled = useAppSelector((state) => state.appConfig.devMode.enabled);

  const [showDialog, setShowDialog] = useState(false);
  const [sizer, setSizer] = useState<HTMLDivElement | null>(null);
  const [dialogWidth, setDialogWidth] = useState(0);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLSpanElement>) => {
      if (event.ctrlKey) {
        // TODO: this will no longer work on mobile, is that okay
        dispatch(appConfigActions.incrementDevModeCount());
        return;
      }
      setShowDialog(true);
    },
    [dispatch]
  );

  useEffect(() => {
    const updateSize = () => {
      setDialogWidth(sizer?.offsetWidth ?? 0);
    };
    updateSize();
    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [sizer]);

  const height = dialogWidth * (3 / 4);

  return (
    <>
      {devModeEnabled ? (
        <Container>
          <ThemeModeToggle />
          <PaletteSelect />
          <FontSelect />
        </Container>
      ) : (
        <Typography onClick={handleClick} variant="subtitle2" color={theme.palette.primary.contrastText}>
          {"It's only game"}
        </Typography>
      )}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div style={{ width: "100%", height, overflow: "hidden" }} ref={setSizer}>
          <iframe
            width={dialogWidth}
            height={height}
            style={{ border: 0 }}
            src="https://www.youtube.com/embed/xzpndHtdl9A?autoplay=1&playlist=xzpndHtdl9A&loop=1&controls=0"
            title="Why you heff to be mad?"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <div style={{ width: "100vw" }} />
        </div>
      </Dialog>
    </>
  );
};
