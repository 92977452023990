import { FunctionComponent, useState, useCallback } from "react";
import { TableRow } from "@mui/material";

import { UfflStartingPosition } from "../../models/uffl";
import { usePositionScore, useTotalScore } from "../../scoring/useScore";

import { Cell, HeaderCell } from "./MatchResultsTableStyles";
import { MatchResultsTableRowDetails } from "./MatchResultsTableRowDetails";
import { useRoundSelection } from "./RoundSelectionContext";

interface MatchResultsCellProps {
  userId: string;
}

const MatchResultsTotalCell: FunctionComponent<MatchResultsCellProps> = ({ userId }) => {
  const { compSeasonId, roundId } = useRoundSelection();
  const score = useTotalScore(compSeasonId, roundId, userId);
  return <Cell>{score ?? 0}</Cell>;
};

interface MatchResultsPositionCellProps extends MatchResultsCellProps {
  position: UfflStartingPosition;
}

const MatchResultsPositionCell: FunctionComponent<MatchResultsPositionCellProps> = ({ userId, position }) => {
  const { compSeasonId, roundId } = useRoundSelection();
  const score = usePositionScore(compSeasonId, roundId, userId, position);
  return <Cell>{score ?? 0}</Cell>;
};

interface MatchResultsTableRowProps extends MatchResultsCellProps {
  teamName: string;
  columns: string[];
  statColumns: UfflStartingPosition[]; // todo: no
}

export const MatchResultsTableRow: FunctionComponent<MatchResultsTableRowProps> = ({
  userId,
  teamName,
  columns,
  statColumns
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  return (
    <>
      <TableRow onClick={toggleOpen}>
        <HeaderCell component="th">{teamName}</HeaderCell>
        {statColumns.map((position) => (
          <MatchResultsPositionCell key={position} position={position} userId={userId} />
        ))}
        <MatchResultsTotalCell userId={userId} />
      </TableRow>
      <MatchResultsTableRowDetails
        userId={userId}
        open={open}
        toggleOpen={toggleOpen}
        columns={columns}
        statColumns={statColumns}
      />
    </>
  );
};
