import { FunctionComponent } from "react";
import { Box, Divider, Stack, Typography, styled } from "@mui/material";

import { RoundResults } from "../../roundResults/RoundResults";
import { Substitutions } from "../../substitutions/Substitutions";

import { ClockToggle } from "../ClockToggle";
import { Colours } from "./Colours";
import { FontSelect } from "./FontSelect";
import { PaletteSelect } from "./PaletteSelect";
import { ThemeModeToggle } from "./ThemeModeToggle";
import { CompSeasonSelect } from "../CompSeasonSelect";

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const CenteredWrapper = styled(Wrapper)`
  align-items: center;
`;

export const Theming: FunctionComponent = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6">Theming</Typography>
      <CenteredWrapper>
        <CompSeasonSelect />
        <ClockToggle />
        <ThemeModeToggle />
        <PaletteSelect />
        <FontSelect />
      </CenteredWrapper>
      <Divider />
      <CenteredWrapper>
        <Colours />
      </CenteredWrapper>
      <Divider />
      <Wrapper>
        <Substitutions />
        <RoundResults />
      </Wrapper>
    </Stack>
  );
};
