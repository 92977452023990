import { FunctionComponent } from "react";
import { Select, selectClasses, outlinedInputClasses, MenuItem } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { themeActions } from "../../../slices/themeSlice";

const fontNames = [
  "Inter",
  "Lato",
  "Lora",
  "Manrope",
  "Merriweather",
  "Montserrat",
  "Open Sans",
  "Poppins",
  "Quicksand",
  "Raleway",
  "Roboto",
  "Rubik",
  "Work Sans"
];

export const FontSelect: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const googleFontName = useAppSelector((state) => state.theme.googleFontName);

  return (
    <Select
      size="small"
      color="secondary"
      displayEmpty
      value={googleFontName}
      onChange={(e) => dispatch(themeActions.setGoogleFontName(e.target.value))}
      sx={{
        color: "currentcolor",
        [`.${selectClasses.icon}`]: {
          color: "currentcolor"
        },
        [`.${outlinedInputClasses.input}`]: {
          color: "currentcolor"
        },
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "currentcolor"
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "currentcolor"
        }
      }}
    >
      <MenuItem disabled value="">
        <em>Select font</em>
      </MenuItem>
      {fontNames.map((fontName) => (
        <MenuItem key={fontName} value={fontName}>
          {fontName}
        </MenuItem>
      ))}
    </Select>
  );
};
