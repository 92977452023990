import { Stack, Typography } from "@mui/material";
import { FunctionComponent } from "react";

import { useAppSelector } from "../../redux/hooks";
import { aflRoundsSelectors } from "../../slices/aflRoundsSlice";
import { RoundFixture } from "./RoundFixture";

export const FixtureManagement: FunctionComponent = () => {
  const aflRounds = useAppSelector(aflRoundsSelectors.selectAll);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
      <Typography variant="h6">Fixture Management</Typography>
      {aflRounds.map((round) => (
        <RoundFixture round={round} key={round.providerId} />
      ))}
    </Stack>
  );
};
