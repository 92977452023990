import { FunctionComponent } from "react";
import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

import { useUfflRounds } from "../../hooks/useUfflRounds";

import { MatchResultsTableRows } from "./MatchResultsTableRows";
import { useRoundSelection } from "./RoundSelectionContext";

export const RoundResultsTable: FunctionComponent = () => {
  const { compSeasonId, roundId } = useRoundSelection();
  const { selectById } = useUfflRounds(compSeasonId);
  const fixture = selectById(roundId)?.fixture;

  return (
    <Table>
      <TableBody>
        {fixture?.length ? (
          fixture.map((match, index) => <MatchResultsTableRows match={match} key={index} />)
        ) : (
          <TableRow>
            <TableCell align="center">
              <Typography variant="body1" pt={2}>
                No fixture for this round
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
