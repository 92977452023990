import { createDraftSafeSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";

import { playerDetailsConverter } from "../models/firestoreDataConverters";
import { getDisplayName, PlayerDetails, PlayerDisplayItem } from "../models/player";
import { createKeysResolver } from "../redux/keysResolver";
import { InferSelectFromResult, selectByIdFactory } from "../redux/selectorFactories";
import { AppendParameters } from "../utils/utilityTypes";

import { firestoreApi, firestoreBaseCollection } from "./firestoreApi";

const aflPlayersAdapter = createEntityAdapter({
  selectId: (e: PlayerDetails & PlayerDisplayItem) => e.playerId,
  sortComparer: (a, b) => a.displayName.localeCompare(b.displayName)
});

const initialState = aflPlayersAdapter.getInitialState();

export const aflPlayersApi = firestoreApi.injectEndpoints({
  endpoints: (build) => ({
    getPlayerDetails: build.query<
      ReturnType<typeof aflPlayersAdapter["getInitialState"]>,
      { firestore: Firestore; compSeasonId: string } // TODO: temporary, prefer for this to be in the base query or just set in a single place
    >({
      queryFn: () => {
        return { data: initialState };
      },
      async onCacheEntryAdded({ firestore, compSeasonId }, { cacheEntryRemoved, getState, updateCachedData }) {
        let unsubscribe;
        try {
          const ref = collection(
            firestoreBaseCollection(firestore, getState),
            "afl",
            "compSeasons",
            compSeasonId,
            "players"
          ).withConverter(playerDetailsConverter);
          unsubscribe = onSnapshot(ref, {
            next: (snapshot) => {
              updateCachedData((draft) => {
                const docs =
                  snapshot?.docs.map((s) => {
                    const data = s.data();
                    return { displayName: getDisplayName(data), ...data };
                  }) ?? [];
                aflPlayersAdapter.setAll(draft, docs);
              });
            },
            error: (error) => {
              console.log("Firestore error: getPlayerDetails", { error });
              // TODO: update cached data with error
            }
          });
        } catch (error) {
          console.log("Uncaught error: getPlayerDetails", { error });
          throw new Error("Something went wrong with getPlayerDetails.");
          // TODO: update cached data with error?
        }

        await cacheEntryRemoved;
        return unsubscribe();
      }
    })
  })
});

export const { useGetPlayerDetailsQuery } = aflPlayersApi;

const { selectById: selectByKnownId, ...selectors } = aflPlayersAdapter.getSelectors(
  (state: InferSelectFromResult<typeof useGetPlayerDetailsQuery>) => state?.data ?? initialState
);

const selectById = selectByIdFactory(selectByKnownId);

const playerIdsKeyResolver = createKeysResolver();

const selectByIds = createDraftSafeSelector(
  [
    selectors.selectAll,
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...[state, ids]: AppendParameters<typeof selectors.selectAll, (ids: string[]) => unknown>
    ) => playerIdsKeyResolver(state, ids)
  ],
  (playerDetails, ids) => playerDetails?.filter((p) => ids.includes(p.playerId))
);

export const aflPlayersSelectors = {
  ...selectors,
  selectById,
  selectByIds
};
