import { FunctionComponent } from "react";
import { Button } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { themeActions } from "../../../slices/themeSlice";

export const ThemeModeToggle: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector((state) => state.theme.options.palette?.mode);

  return (
    <Button
      onClick={() => dispatch(themeActions.toggleDarkMode())}
      variant="outlined"
      color="inherit"
      sx={{ fontSize: "inherit" }}
    >
      {themeMode}
    </Button>
  );
};
