import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThemeOptions } from "@mui/material";

import { ColourPaletteName, colourPalettes } from "../theme/colours";

const defaultColourPaletteName = ColourPaletteName.Mindful;

export interface ThemeState {
  colourPaletteName: ColourPaletteName;
  googleFontName: string;
  options: ThemeOptions;
}

const defaultGoogleFontName = "Raleway";
const defaultFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
const fontFamily = (googleFont: string) => (googleFont ? `"${googleFont}", ${defaultFontFamily}` : defaultFontFamily);

const initialState: ThemeState = {
  colourPaletteName: defaultColourPaletteName,
  googleFontName: defaultGoogleFontName,
  options: {
    palette: colourPalettes[defaultColourPaletteName],
    typography: {
      fontFamily: fontFamily(defaultGoogleFontName)
    }
  }
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.options = {
        ...state.options,
        palette: {
          ...state.options.palette,
          mode: state.options.palette?.mode === "dark" ? "light" : "dark"
        }
      };
    },
    setGoogleFontName: (state, action: PayloadAction<string>) => {
      state.googleFontName = action.payload;
      state.options = {
        ...state.options,
        typography: {
          ...state.options.typography,
          fontFamily: fontFamily(action.payload)
        }
      };
    },
    setColourPaletteName: (state, action: PayloadAction<ColourPaletteName>) => {
      state.colourPaletteName = action.payload;
      state.options.palette = colourPalettes[action.payload];
    },
    setPaletteColour: (
      state,
      action: PayloadAction<{
        name: "primary" | "secondary" | "success" | "error" | "warning" | "info";
        hexValue: `#${string}`;
        token?: "main";
      }>
    ) => {
      state.options = {
        ...state.options,
        palette: {
          ...state.options.palette,
          [action.payload.name]: { [action.payload.token ?? "main"]: action.payload.hexValue }
        }
      };
    }
  }
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;
