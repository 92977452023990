import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AflRound } from "../models/round";

export interface RoundStatusState {
  currentRound: AflRound | undefined;
  nextRound: AflRound | undefined;
}

const initialState: RoundStatusState = {
  currentRound: undefined,
  nextRound: undefined
};

export const roundStatusSlice = createSlice({
  name: "roundStatus",
  reducerPath: "roundStatus",
  initialState,
  reducers: {
    setCurrentRound: (state, action: PayloadAction<AflRound>) => {
      // console.log("doing setCurrentRound");
      state.currentRound = action.payload;
    },
    setNextRound: (state, action: PayloadAction<AflRound>) => {
      // console.log("doing setNextRound");
      state.nextRound = action.payload;
    }
  },
  selectors: {
    selectCurrentRound: (state) => state.currentRound,
    selectNextRound: (state) => state.nextRound
  }
});

export const roundStatusActions = roundStatusSlice.actions;

export const roundStatusSelectors = {
  ...roundStatusSlice.selectors
};
