import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { aflRoundsSlice } from "../slices/aflRoundsSlice";
import { appConfigSlice } from "../slices/appConfigSlice";
import currentUserReducer from "../slices/currentUserSlice";
import { firestoreApi } from "../slices/firestoreApi";
import { roundStatusSlice } from "../slices/roundStatusSlice";
import { scoringRulesSlice } from "../slices/scoringRulesSlice";
import themeReducer from "../slices/themeSlice";

export const store = configureStore({
  reducer: {
    [appConfigSlice.reducerPath]: appConfigSlice.reducer,
    [firestoreApi.reducerPath]: firestoreApi.reducer,
    currentUser: currentUserReducer,
    [aflRoundsSlice.reducerPath]: aflRoundsSlice.reducer,
    [roundStatusSlice.reducerPath]: roundStatusSlice.reducer,
    [scoringRulesSlice.reducerPath]: scoringRulesSlice.reducer,
    theme: themeReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(firestoreApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
