import { FunctionComponent } from "react";
import { Stack } from "@mui/material";

import { RoundResultsTable } from "./RoundResultsTable";
import { RoundSelectors } from "./RoundSelectors";
import { RoundSelectionProvider } from "./RoundSelectionContext";

export const RoundResults: FunctionComponent = () => (
  <RoundSelectionProvider>
    <Stack>
      <RoundSelectors />
      <RoundResultsTable />
    </Stack>
  </RoundSelectionProvider>
);
