import { createDraftSafeSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";

import { ufflUserRoundConverter } from "../models/firestoreDataConverters";
import { UfflUserRound } from "../models/round";
import { InferSelectFromResult, selectByIdFactory } from "../redux/selectorFactories";

import { firestoreApi, firestoreBaseCollection } from "./firestoreApi";
import { AppendParameters } from "../utils/utilityTypes";
import { positionSubs, UfflStartingPosition } from "../models/uffl";

const ufflRoundTeamsAdapter = createEntityAdapter({
  selectId: (e: UfflUserRound) => e.uid
});

const initialState = ufflRoundTeamsAdapter.getInitialState();

export const ufflRoundTeamsApi = firestoreApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getUfflRoundTeams: build.query<
      ReturnType<typeof ufflRoundTeamsAdapter["getInitialState"]>,
      { firestore: Firestore; compSeasonId: string; roundId: string } // TODO: temporary, prefer for this to be in the base query or just set in a single place
    >({
      queryFn: () => {
        return { data: initialState };
      },
      async onCacheEntryAdded({ firestore, compSeasonId, roundId }, { cacheEntryRemoved, getState, updateCachedData }) {
        let unsubscribe;
        try {
          const ref = collection(
            firestoreBaseCollection(firestore, getState),
            "uffl",
            "compSeasons",
            compSeasonId,
            "rounds",
            roundId,
            "teams"
          ).withConverter(ufflUserRoundConverter);
          unsubscribe = onSnapshot(ref, {
            next: (snapshot) => {
              updateCachedData((draft) => {
                const docs = snapshot?.docs.map((s) => ({ roundId, ...s.data() })) ?? [];
                // console.log("getUfflRoundTeams updateCachedData", { draft, snapshot, docs: docs });
                ufflRoundTeamsAdapter.upsertMany(draft, docs);
              });
            },
            error: (error) => {
              console.log("Firestore error: getUfflRounds", { error });
              // TODO: update cached data with error
            }
          });
        } catch (error) {
          console.log("Uncaught error: getUfflRounds", { error });
          throw new Error("Something went wrong with getUfflRounds.");
          // TODO: update cached data with error?
        }

        await cacheEntryRemoved;
        return unsubscribe();
      }
    })
  })
});

export const { useGetUfflRoundTeamsQuery } = ufflRoundTeamsApi;

const { selectById: selectByKnownId, ...selectors } = ufflRoundTeamsAdapter.getSelectors(
  (state: InferSelectFromResult<typeof useGetUfflRoundTeamsQuery>) => state?.data ?? initialState
);

const selectById = selectByIdFactory(selectByKnownId);

const selectLineupPlayer = createDraftSafeSelector(
  [
    selectById,
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...[state, uid, position]: AppendParameters<typeof selectById, (position: UfflStartingPosition) => unknown>
    ) => position as UfflStartingPosition
  ],
  (team, position) => {
    team?.lineup?.[position];
  }
);

const selectScoringPlayerId = createDraftSafeSelector(
  [
    selectById,
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...[state, uid, position]: AppendParameters<typeof selectById, (position: UfflStartingPosition) => unknown>
    ) => position as UfflStartingPosition
  ],
  (team, position) => {
    const lineupPlayer = team?.lineup?.[position];
    if (!lineupPlayer?.substituted) {
      return lineupPlayer?.playerId;
    }

    const substitutedPlayer = team?.lineup?.[positionSubs[position]];
    return substitutedPlayer?.playerId;
  }
);

export const ufflRoundTeamsSelectors = {
  ...selectors,
  selectById,
  selectLineupPlayer,
  selectScoringPlayerId
};
