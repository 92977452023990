import { FunctionComponent, useMemo } from "react";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { FirestoreProvider, AuthProvider, useFirebaseApp } from "reactfire";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";

import { Gui } from "../gui/Gui";
import { useAppSelector } from "../redux/hooks";
import { FontWatcher } from "../theme/FontWatcher";
import { Router } from "./Router";

export const App: FunctionComponent = () => {
  const authInstance = getAuth(useFirebaseApp());
  const firestoreInstance = getFirestore(useFirebaseApp());

  const themeOptions = useAppSelector((state) => state.theme.options);
  const theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FontWatcher />
      <AuthProvider sdk={authInstance}>
        <FirestoreProvider sdk={firestoreInstance}>
          <Router>
            <Gui />
          </Router>
        </FirestoreProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};
