import {
  FunctionComponent,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  PropsWithChildren
} from "react";
import { useAflRounds } from "../../hooks/useAflRounds";

import { useAppSelector } from "../../redux/hooks";
import { ScoringSeason } from "../../scoring/models";
import { appConfigSelectors } from "../../slices/appConfigSlice";
import { roundStatusSelectors } from "../../slices/roundStatusSlice";

interface RoundSelectionState {
  compSeasonId: ScoringSeason;
  setCompSeasonId: Dispatch<SetStateAction<ScoringSeason>>;
  roundId: string;
  setRoundId: Dispatch<SetStateAction<string>>;
  showAllRounds: boolean;
}

const defaultCallback = () => {
  throw new Error("This may only be used within RoundSelectionProvider.");
};

const defaultRoundSelectionState: RoundSelectionState = {
  compSeasonId: ScoringSeason.CD_S2025014,
  setCompSeasonId: defaultCallback,
  roundId: "placeholderRoundId",
  setRoundId: defaultCallback,
  showAllRounds: true
};

const RoundSelectionContext = createContext<RoundSelectionState>(defaultRoundSelectionState);

export const useRoundSelection = () => useContext(RoundSelectionContext);

export const RoundSelectionProvider: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  const appCompSeasonId = useAppSelector(appConfigSelectors.selectCompSeasonId);
  const [compSeasonId, setCompSeasonId] = useState(appCompSeasonId);
  const { selectById: selectRoundById } = useAflRounds(compSeasonId);
  const currentRoundId = useAppSelector(roundStatusSelectors.selectCurrentRound)?.providerId;
  const [_roundId, setRoundId] = useState(currentRoundId ?? defaultRoundSelectionState.roundId);

  const selectedRoundId = useMemo(
    () => (_roundId && _roundId !== defaultRoundSelectionState.roundId ? _roundId : currentRoundId),
    [_roundId, currentRoundId]
  );

  const roundId = useMemo(
    // make sure our roundId corresponds with a round in this compSeason
    () => selectRoundById(selectedRoundId)?.providerId ?? defaultRoundSelectionState.roundId,
    [selectedRoundId, selectRoundById]
  );

  return (
    <RoundSelectionContext.Provider
      value={{
        compSeasonId,
        setCompSeasonId,
        roundId,
        setRoundId,
        showAllRounds: defaultRoundSelectionState.showAllRounds
      }}
    >
      {children}
    </RoundSelectionContext.Provider>
  );
};
