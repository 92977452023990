import { FunctionComponent, useMemo } from "react";
import { MenuItem, Select, Stack } from "@mui/material";
import { filter } from "lodash";
import { useFirestore } from "reactfire";

import { ScoringSeason } from "../../scoring/models";
import { aflCompSeasonsSelectors, useGetAflCompSeasonsQuery } from "../../slices/aflCompSeasonsApi";
import { isEnumMember } from "../../utils/objectUtils";
import { Last } from "../../utils/utilityTypes";

import { useRoundSelection } from "./RoundSelectionContext";
import { useAflRounds } from "../../hooks/useAflRounds";

const CompSeasonSelect: FunctionComponent = () => {
  const { compSeasonId, setCompSeasonId } = useRoundSelection();

  const { selectAll: compSeasons, selectById } = useGetAflCompSeasonsQuery(useFirestore(), {
    selectFromResult: (result) => ({
      ...result,
      selectAll: aflCompSeasonsSelectors.selectAll(result),
      selectById: (id: Last<Parameters<typeof aflCompSeasonsSelectors.selectById>>) =>
        aflCompSeasonsSelectors.selectById(result, id)
    })
  });

  return (
    <Select
      size="small"
      value={selectById(compSeasonId) ? compSeasonId : ""}
      onChange={({ target: { value } }) => {
        if (isEnumMember(ScoringSeason, value)) {
          setCompSeasonId(value);
        }
      }}
    >
      {compSeasons.map(({ name, providerId }) => (
        <MenuItem key={providerId} value={providerId}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

const RoundSelect: FunctionComponent = () => {
  const { compSeasonId, roundId, setRoundId, showAllRounds } = useRoundSelection();
  const { selectAll: aflRounds } = useAflRounds(compSeasonId);

  const rounds = useMemo(
    () => (showAllRounds || !roundId ? aflRounds : filter(aflRounds, (r) => r.providerId <= roundId)),
    [roundId, showAllRounds, aflRounds]
  );

  return (
    <Select displayEmpty value={roundId} onChange={(e) => setRoundId(e.target.value)} size="small">
      <MenuItem disabled value="placeholderRoundId">
        <em>Select a round</em>
      </MenuItem>
      {rounds.map((round) => (
        <MenuItem value={round.providerId} key={round.providerId}>
          {round.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export const RoundSelectors: FunctionComponent = () => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <CompSeasonSelect />
      <RoundSelect />
    </Stack>
  );
};
