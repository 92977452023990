import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Paper, Stack, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { mapValues, some } from "lodash";
import { FunctionComponent, useEffect, useMemo, useReducer, useState } from "react";
import { useFirestore } from "reactfire";

import { useCompSeasonId } from "../../hooks/useCompSeasonId";
import { useFirestoreBasePath } from "../../hooks/useFirestoreBasePath";
import { useUfflRounds } from "../../hooks/useUfflRounds";
import { AflRound } from "../../models/round";
import { UfflMatch } from "../../models/uffl";

import { IconButton } from "../uiElements/IconButton";
import { FixtureMatch } from "./FixtureMatch";
import { isError, UserSelectError } from "./UserSelect";

interface RoundFixtureProps {
  round: AflRound;
}

export const RoundFixture: FunctionComponent<RoundFixtureProps> = ({ round }) => {
  const compSeasonId = useCompSeasonId();
  const [editMode, setEditMode] = useState(false);

  const { selectById } = useUfflRounds(compSeasonId);
  const ufflRound = selectById(round.providerId);

  // TODO: no state unless actively editing
  const [fixture, setFixture] = useReducer(
    (state: UfflMatch[], action: { type: "revert" } | { type: "update"; index: number; match: UfflMatch | null }) => {
      switch (action.type) {
        case "revert":
          // TODO: not sure this is doing what i want it to do
          return ufflRound?.fixture ?? [];
        case "update": {
          if (action.index >= state.length) {
            if (!action.match) {
              return state;
            }
          }

          const newState = state.slice();
          switch (action.match) {
            case null:
              newState.splice(action.index, 1);
              break;
            default:
              if (action.index < state.length) {
                newState.splice(action.index, 1, action.match);
                break;
              }
              newState.push(action.match);
              break;
          }

          return newState;
        }
        default:
          return state;
      }
    },
    ufflRound?.fixture ?? []
  );

  // TODO: not sure this is doing what i want it to do
  useEffect(() => setFixture({ type: "revert" }), [ufflRound?.fixture]);

  const changed = useMemo(() => {
    if (!editMode) {
      return false;
    }

    const source = ufflRound?.fixture ?? [];
    if (fixture.length !== source.length) {
      return true;
    }

    let modified = false;

    for (let i = 0; !modified && i < Math.max(fixture.length, source.length); i += 1) {
      const oldMatch = source.at(i);
      const newMatch = fixture.at(i);

      // TODO: i hate this but it'll do for now
      if (
        oldMatch?.user1 !== newMatch?.user1 ||
        oldMatch?.user2 !== newMatch?.user2 ||
        oldMatch?.user3 !== newMatch?.user3
      ) {
        modified = true;
      }
    }

    return modified;
  }, [editMode, fixture, ufflRound?.fixture]);

  const validationErrors = useMemo(() => {
    // if (!changed) {
    //   return null;
    // }

    const uidCounts: Partial<Record<string, number>> = {};
    fixture.forEach((m) => {
      Object.values(m).forEach((v) => {
        uidCounts[v] = (uidCounts[v] ?? 0) + 1;
      });
    });

    const errors = fixture.map((m) => {
      return mapValues(m, (uid) => {
        if (!uid) {
          return UserSelectError.NoUser;
        }

        if ((uidCounts[uid] ?? 0) > 1) {
          return UserSelectError.DuplicateUser;
        }

        // TODO
        /*
        if (someCondition) {
          return UserSelectError.InvalidUser;
        }
        */

        return UserSelectError.NoError;
      });
    });

    return errors;
  }, [
    // changed,
    fixture
  ]);

  const invalid = useMemo(() => validationErrors?.some((m) => some(m, isError)), [validationErrors]);

  const firestore = useFirestore();
  const firestoreBasePath = useFirestoreBasePath();
  const docRef = useMemo(
    () => doc(firestore, firestoreBasePath, "uffl", "compSeasons", compSeasonId, "rounds", round.providerId),
    [firestore, firestoreBasePath, compSeasonId, round.providerId]
  );

  return (
    <TableContainer component={Paper} sx={{ padding: "0.5em 0" }}>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <Typography variant="body1">{round.name}</Typography>
        {editMode ? (
          <>
            <IconButton
              variant="contained"
              disabled={!changed || invalid}
              onClick={() => {
                if (!changed || invalid) {
                  return;
                }

                console.log("TODO: update firebase data", {
                  fixture: fixture.slice(),
                  source: ufflRound?.fixture?.slice(),
                  docRef,
                  firestoreBasePath,
                  compSeasonId,
                  roundId: round.providerId
                });

                setDoc(docRef, { fixture }, { merge: true });
                setEditMode(false);
              }}
            >
              <CheckIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              variant="contained"
              onClick={() => {
                setFixture({ type: "revert" });
                setEditMode(false);
              }}
            >
              <CancelIcon fontSize="inherit" />
            </IconButton>
          </>
        ) : (
          <IconButton variant="contained" onClick={() => setEditMode(true)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
      <Table>
        <TableBody>
          {fixture.map((m, index) => (
            <FixtureMatch
              match={m}
              setMatch={(match) => setFixture({ type: "update", index, match })}
              editMode={editMode}
              errors={validationErrors?.at(index)}
              key={index.toString()}
            />
          ))}
        </TableBody>
      </Table>
      {editMode ? (
        <IconButton
          variant="contained"
          onClick={() => setFixture({ type: "update", index: fixture.length ?? 0, match: { user1: "", user2: "" } })}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </TableContainer>
  );
};
