import { createEntityAdapter } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";

import { aflRoundConverter } from "../models/firestoreDataConverters";
import { AflRound } from "../models/round";
import { InferSelectFromResult, selectByIdFactory } from "../redux/selectorFactories";

import { firestoreApi, firestoreBaseCollection } from "./firestoreApi";

const aflRoundsAdapter = createEntityAdapter({
  selectId: (e: AflRound) => e.providerId
});

const initialState = aflRoundsAdapter.getInitialState();

export const aflRoundsApi = firestoreApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getAflRounds: build.query<
      ReturnType<typeof aflRoundsAdapter["getInitialState"]>,
      { firestore: Firestore; compSeasonId: string } // TODO: temporary, prefer for this to be in the base query or just set in a single place
    >({
      queryFn: () => ({ data: initialState }),
      async onCacheEntryAdded({ firestore, compSeasonId }, { cacheEntryRemoved, getState, updateCachedData }) {
        let unsubscribe;
        try {
          const ref = collection(
            firestoreBaseCollection(firestore, getState),
            "afl",
            "compSeasons",
            compSeasonId,
            "rounds"
          ).withConverter(aflRoundConverter);
          unsubscribe = onSnapshot(ref, {
            next: (snapshot) => {
              updateCachedData((draft) => {
                const docs = snapshot?.docs.map((s) => s.data()) ?? [];
                // console.log("getAflRounds updateCachedData", { draft, snapshot, docs: docs });
                aflRoundsAdapter.setAll(draft, docs);
              });
            },
            error: (error) => {
              console.log("Firestore error: getAflRounds", { error });
              // TODO: update cached data with error
            }
          });
        } catch (error) {
          console.log("Uncaught error: getAflRounds", { error });
          throw new Error("Something went wrong with getAflRounds.");
          // TODO: update cached data with error?
        }

        await cacheEntryRemoved;
        return unsubscribe();
      }
    })
  })
});

export const { useGetAflRoundsQuery } = aflRoundsApi;

const { selectById: selectByKnownId, ...selectors } = aflRoundsAdapter.getSelectors(
  (state: InferSelectFromResult<typeof useGetAflRoundsQuery>) => state?.data ?? initialState
);

export const aflRoundsSelectors = {
  ...selectors,
  selectById: selectByIdFactory(selectByKnownId)
};
