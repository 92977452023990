import { BaseQueryApi, createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { collection } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";

import { InferRootState } from "../redux/selectorFactories";
import { appConfigSelectors } from "./appConfigSlice";

export const firestoreBaseCollection = (firestore: Firestore, getState: BaseQueryApi["getState"]) => {
  const firestoreBasePath = appConfigSelectors.selectFirestoreBasePath(
    getState() as InferRootState<typeof appConfigSelectors.selectFirestoreBasePath>
  );
  return collection(firestore, firestoreBasePath);
};

export const firestoreApi = createApi({
  reducerPath: "firestoreApi",
  baseQuery: fakeBaseQuery(), // TODO: firestoreBaseCollection
  endpoints: () => ({})
});
