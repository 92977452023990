import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { memoize as lodashMemoize } from "lodash";

type KeysResolver<T extends string = string> = (state: unknown, keys: T[]) => string;

export const defaultKeysResolver = <T extends string = string>(state: unknown, keys: T[]): string => {
  return keys
    .slice()
    .sort((a, b) => a.localeCompare(b))
    .join("|");
};

export function createKeysResolver<T extends string = string>(resolver: KeysResolver<T> = defaultKeysResolver) {
  return createDraftSafeSelector([resolver, (...[, keys]: Parameters<typeof resolver>) => keys], (key, keys) => keys, {
    memoizeOptions: (key) => key,
    memoize: lodashMemoize
  });
}
