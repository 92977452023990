import { useFirestore } from "reactfire";

import { ufflRoundsSelectors, useGetUfflRoundsQuery } from "../slices/ufflRoundsApi";
import { DropFirst } from "../utils/utilityTypes";

export const useUfflRounds = (compSeasonId: string) =>
  useGetUfflRoundsQuery(
    { firestore: useFirestore(), compSeasonId },
    {
      selectFromResult: (result) => ({
        ...result,
        selectAll: ufflRoundsSelectors.selectAll(result),
        selectById: (...[providerId]: DropFirst<Parameters<typeof ufflRoundsSelectors.selectById>>) =>
          ufflRoundsSelectors.selectById(result, providerId)
      })
    }
  );
