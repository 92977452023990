import { FunctionComponent, useMemo } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

import { useUfflUsers } from "../../hooks/useUfflUsers";
import { UfflMatch } from "../../models/uffl";

import { MatchResultsTableRow } from "./MatchResultsTableRow";
import { HeaderCell } from "./MatchResultsTableStyles";
import { useRoundSelection } from "./RoundSelectionContext";
import { useAppSelector } from "../../redux/hooks";
import { scoringRulesSelectors } from "../../slices/scoringRulesSlice";

export const MatchResultsTableRows: FunctionComponent<{
  match: UfflMatch;
}> = ({ match }) => {
  const { compSeasonId } = useRoundSelection();

  const statColumns = useAppSelector((state) => scoringRulesSelectors.selectPositions(state, compSeasonId));

  // TODO: make this another selector probably jfc
  const columns = useMemo(() => {
    return ["Team name", ...statColumns, "Total"];
  }, [statColumns]);

  const { selectById: selectUser } = useUfflUsers();

  const teams = useMemo(
    () => Object.values(match).map((uid) => selectUser(uid) ?? { uid, teamName: "Unknown" }),
    [match, selectUser]
  );

  return (
    <>
      <TableRow>
        <TableCell align="center" colSpan={columns.length}>
          <Typography variant="body1" pt={2}>
            {teams.map((team) => team.teamName).join(" vs. ")}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {columns.map((heading) => {
          return <HeaderCell key={heading}>{heading}</HeaderCell>;
        })}
      </TableRow>
      {teams.map((team) => (
        <MatchResultsTableRow
          userId={team.uid}
          teamName={team.teamName}
          columns={columns}
          statColumns={statColumns}
          key={team.uid}
        />
      ))}
    </>
  );
};
