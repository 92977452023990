import { PaletteOptions } from "@mui/material";

export enum ColourPaletteName {
  ItsOnlyGame = "ItsOnlyGame",
  LookItUp = "LookItUp",
  ToTheMoon = "ToTheMoon",
  Brenda = "Brenda",
  RadDog = "RadDog",
  Mindful = "Mindful"
}

export const colourPalettes: Record<ColourPaletteName, PaletteOptions> = {
  [ColourPaletteName.ItsOnlyGame]: {
    mode: "dark",
    primary: { main: "#aeea00" },
    secondary: { main: "#3b00ea" }
  },
  [ColourPaletteName.LookItUp]: {
    mode: "dark",
    primary: { main: "#c2a2fb" },
    secondary: { main: "#39ccd0" },
    success: { main: "#6db073" },
    error: { main: "#f96f57" },
    warning: { main: "#f9d85f" },
    info: { main: "#f895e5" }
  },
  [ColourPaletteName.ToTheMoon]: {
    mode: "dark",
    primary: { main: "#49f9d4" },
    secondary: { main: "#735af8" },
    success: { main: "#49f97b" },
    error: { main: "#f9496f" },
    warning: { main: "#e0f85a" },
    info: { main: "#2dade8" }
  },
  [ColourPaletteName.Brenda]: {
    mode: "dark",
    primary: { main: "#f895e5" },
    secondary: { main: "#39ccd0" },
    success: { main: "#6db073" },
    error: { main: "#f96f57" },
    warning: { main: "#f9d85f" },
    info: { main: "#c2a2fb" }
  },
  [ColourPaletteName.RadDog]: {
    mode: "dark",
    primary: { main: "#e54873" },
    secondary: { main: "#62bcaa" },
    success: { main: "#98c658" },
    error: { main: "#e7734c" },
    warning: { main: "#f2e340" },
    info: { main: "#6f7ddb" }
  },
  [ColourPaletteName.Mindful]: {
    mode: "dark",
    primary: { main: "#6a72dc" },
    secondary: { main: "#f640c6" },
    success: { main: "#4bd2c0" },
    error: { main: "#e57683" },
    warning: { main: "#f19964" },
    info: { main: "#59becf" }
  }
};
