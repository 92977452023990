import { FirestoreDataConverter } from "firebase/firestore";
import { mapValues } from "lodash";

import { AflCompSeason, AflMatch } from "./afl";
import { PlayerDetails, PlayerStats, PlayerStatsFull } from "./player";
import { AflRound, UfflRound, UfflUserRound } from "./round";
import { UfflUser } from "./uffl";

export const aflCompSeasonConverter: FirestoreDataConverter<AflCompSeason> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => snapshot.data(options) as AflCompSeason
};

export const aflMatchConverter: FirestoreDataConverter<AflMatch> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => snapshot.data(options) as AflMatch
};

export const aflRoundConverter: FirestoreDataConverter<AflRound> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => ({ providerId: snapshot.id, ...snapshot.data(options) } as AflRound)
};

export const playerDetailsConverter: FirestoreDataConverter<PlayerDetails> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => ({ playerId: snapshot.id, ...snapshot.data(options) } as PlayerDetails)
};

export const playerStatsConverter: FirestoreDataConverter<PlayerStats> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => ({ playerId: snapshot.id, ...snapshot.data(options) } as PlayerStats)
};

export const playerStatsFullConverter: FirestoreDataConverter<PlayerStatsFull> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return { playerId: snapshot.id, ...data.clearances, ...data } as PlayerStatsFull;
  }
};

export const ufflUserConverter: FirestoreDataConverter<UfflUser> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options): UfflUser => ({ uid: snapshot.id, teamName: "unknown", ...snapshot.data(options) })
};

export const ufflUserRoundConverter: FirestoreDataConverter<UfflUserRound> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options): UfflUserRound => ({ uid: snapshot.id, ...snapshot.data(options) })
};

export const ufflRoundConverter: FirestoreDataConverter<UfflRound> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options): UfflRound => ({ providerId: snapshot.id, ...snapshot.data(options) } as UfflRound)
};
