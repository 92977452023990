import { useFirestore } from "reactfire";

import { aflRoundsSelectors, useGetAflRoundsQuery } from "../slices/aflRoundsApi";
import { DropFirst } from "../utils/utilityTypes";

export const useAflRounds = (compSeasonId: string) =>
  useGetAflRoundsQuery(
    { firestore: useFirestore(), compSeasonId },
    {
      selectFromResult: (result) => ({
        ...result,
        selectAll: aflRoundsSelectors.selectAll(result),
        selectById: (...[providerId]: DropFirst<Parameters<typeof aflRoundsSelectors.selectById>>) =>
          aflRoundsSelectors.selectById(result, providerId)
      })
    }
  );
