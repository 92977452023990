import { FunctionComponent, useMemo } from "react";
import { Box, TableCell, TableRow, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { UfflMatch } from "../../models/uffl";
import { IconButton } from "../uiElements/IconButton";
import { UserSelect, UserSelectError } from "./UserSelect";

const Cell = styled(TableCell)`
  padding: 0;
  border: 0;
`;

const CenteredBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface MatchUserSelectProps {
  userKey: keyof UfflMatch;
  onChange: (userKey: keyof UfflMatch, uid: string) => void;
  match: UfflMatch;
  editMode: boolean;
  errors: Partial<Record<keyof UfflMatch, UserSelectError | undefined>> | undefined;
}

const MatchUserSelect: FunctionComponent<MatchUserSelectProps> = ({ userKey, onChange, match, editMode, errors }) => {
  const userId = useMemo(() => match[userKey], [match, userKey]);
  const error = useMemo(
    () => (editMode ? errors?.[userKey] : undefined) ?? UserSelectError.NoError,
    [editMode, errors, userKey]
  );

  return (
    <Cell>
      {userId === undefined ? (
        editMode ? (
          <CenteredBox>
            <IconButton variant="contained" onClick={() => onChange(userKey, "")}>
              <AddIcon fontSize="inherit" />
            </IconButton>
          </CenteredBox>
        ) : null
      ) : (
        <UserSelect editMode={editMode} userId={userId} setUserId={(uid) => onChange(userKey, uid)} error={error} />
      )}
    </Cell>
  );
};

interface FixtureMatchProps {
  match: UfflMatch;
  setMatch: (match: UfflMatch | null) => void;
  editMode: boolean;
  errors: Partial<Record<keyof UfflMatch, UserSelectError | undefined>> | undefined;
}

export const FixtureMatch: FunctionComponent<FixtureMatchProps> = ({ match, setMatch, editMode, errors }) => {
  const onChange = (userKey: keyof UfflMatch, uid: string): void => {
    setMatch({ ...match, [userKey]: uid });
  };

  return (
    <TableRow>
      <Cell>
        {editMode ? (
          <CenteredBox>
            <IconButton variant="contained" onClick={() => setMatch(null)}>
              <RemoveIcon fontSize="inherit" />
            </IconButton>
          </CenteredBox>
        ) : null}
      </Cell>
      <MatchUserSelect userKey="user1" onChange={onChange} match={match} errors={errors} editMode={editMode} />
      <MatchUserSelect userKey="user2" onChange={onChange} match={match} errors={errors} editMode={editMode} />
      <MatchUserSelect userKey="user3" onChange={onChange} match={match} errors={errors} editMode={editMode} />
    </TableRow>
  );
};
