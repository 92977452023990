import { LineupPlayer } from "./player";

export interface UfflUser {
  uid: string;
  teamName: string;
  isAdmin?: boolean;
}

export enum UfflStartingPosition {
  FBK = "FBK",
  HBK = "HBK",
  MD1 = "MD1",
  MD2 = "MD2",
  SPD = "SPD",
  HFW = "HFW",
  FFW = "FFW"
  // IMD = "IMD",
  // OMD = "OMD"
}

export enum UfflReservePosition {
  RBK = "RBK",
  RMD = "RMD",
  RSP = "RSP",
  RFW = "RFW"
}

export const UfflPositions = {
  ...UfflStartingPosition,
  ...UfflReservePosition
};

export type UfflPosition = keyof typeof UfflPositions;

export type Lineup = Record<UfflPosition, LineupPlayer>;

export const positionSubs: Record<UfflStartingPosition, UfflReservePosition> = {
  [UfflStartingPosition.FBK]: UfflReservePosition.RBK,
  [UfflStartingPosition.HBK]: UfflReservePosition.RBK,
  [UfflStartingPosition.MD1]: UfflReservePosition.RMD,
  [UfflStartingPosition.MD2]: UfflReservePosition.RMD,
  [UfflStartingPosition.SPD]: UfflReservePosition.RSP,
  [UfflStartingPosition.HFW]: UfflReservePosition.RFW,
  [UfflStartingPosition.FFW]: UfflReservePosition.RFW
};

export const subsPositions: Record<UfflReservePosition, UfflStartingPosition[]> = {
  RBK: [UfflStartingPosition.FBK, UfflStartingPosition.HBK],
  RMD: [UfflStartingPosition.MD1, UfflStartingPosition.MD2],
  RSP: [UfflStartingPosition.SPD],
  RFW: [UfflStartingPosition.FFW, UfflStartingPosition.HFW]
};

// this needs a lot more thought
export interface PlayerTrade {
  in: string; // playerId
  out: string; // playerId
  tradedWith: string; // userId
  completed: boolean;
  approved?: boolean;
  cancelled?: boolean;
}

export type UfflMatch = {
  user1: string;
  user2: string;
  user3?: string;
};
