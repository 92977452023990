import { createEntityAdapter } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import type { Firestore } from "firebase/firestore";

import { AflCompSeason } from "../models/afl";
import { aflCompSeasonConverter } from "../models/firestoreDataConverters";
import { InferSelectFromResult, selectByIdFactory } from "../redux/selectorFactories";

import { firestoreApi, firestoreBaseCollection } from "./firestoreApi";

const aflCompSeasonsAdapter = createEntityAdapter({
  selectId: (e: AflCompSeason) => e.providerId,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const initialState = aflCompSeasonsAdapter.getInitialState();

export const aflCompSeasonsApi = firestoreApi.injectEndpoints({
  endpoints: (build) => ({
    getAflCompSeasons: build.query<
      ReturnType<typeof aflCompSeasonsAdapter["getInitialState"]>,
      Firestore // TODO: temporary, prefer for this to be in the base query or just set in a single place
    >({
      queryFn: () => {
        return { data: initialState };
      },
      async onCacheEntryAdded(firestore, { cacheEntryRemoved, getState, updateCachedData }) {
        let unsubscribe;
        try {
          const ref = collection(firestoreBaseCollection(firestore, getState), "afl", "compSeasons").withConverter(
            aflCompSeasonConverter
          );
          unsubscribe = onSnapshot(ref, {
            next: (snapshot) => {
              updateCachedData((draft) => {
                const docs = snapshot?.docs.map((s) => s.data()) ?? [];
                // console.log("aflCompSeasonsApi updateCachedData", { draft, snapshot, docs: docs });
                aflCompSeasonsAdapter.setAll(draft, docs);
              });
            },
            error: (error) => {
              console.log("Firestore error: getAflCompSeasons", { error });
              // TODO: update cached data with error
            }
          });
        } catch (error) {
          console.log("Uncaught error: getAflCompSeasons", { error });
          throw new Error("Something went wrong with getAflCompSeasons.");
          // TODO: update cached data with error?
        }

        await cacheEntryRemoved;
        return unsubscribe();
      }
    })
  })
});

export const { useGetAflCompSeasonsQuery } = aflCompSeasonsApi;

const { selectById: selectByKnownId, ...selectors } = aflCompSeasonsAdapter.getSelectors(
  (state: InferSelectFromResult<typeof useGetAflCompSeasonsQuery>) => state?.data ?? initialState
);

export const aflCompSeasonsSelectors = {
  ...selectors,
  selectById: selectByIdFactory(selectByKnownId)
};
