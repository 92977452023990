import { FunctionComponent, useMemo } from "react";
import { MenuItem, FormControl, Select, FormHelperText } from "@mui/material";

import { useUfflUsers } from "../../hooks/useUfflUsers";

export enum UserSelectError {
  NotValidated = "not validated",
  NoError = "no error",
  NoUser = "no user selected",
  InvalidUser = "invalid user selected",
  DuplicateUser = "duplicate user selected"
}

export const isError = (error: UserSelectError | null | undefined) =>
  error ? error !== UserSelectError.NoError : false;

interface UserSelectProps {
  userId: string;
  setUserId: (userId: string) => void;
  error: UserSelectError;
  editMode?: boolean;
}

export const UserSelect: FunctionComponent<UserSelectProps> = ({ userId, setUserId, error, editMode }) => {
  const { selectAll: ufflUsers } = useUfflUsers();
  const hasError = useMemo(() => isError(error), [error]);

  return (
    <FormControl error={hasError} fullWidth>
      <Select size="small" value={userId} onChange={(e) => setUserId(e.target.value)} disabled={!editMode}>
        {ufflUsers?.map(({ uid, teamName }) => (
          <MenuItem value={uid} key={uid}>
            {teamName}
          </MenuItem>
        ))}
      </Select>
      {hasError ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};
