import { FunctionComponent } from "react";
import { Select, selectClasses, outlinedInputClasses, MenuItem } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { themeActions } from "../../../slices/themeSlice";
import { ColourPaletteName } from "../../../theme/colours";

export const PaletteSelect: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const colourPaletteName = useAppSelector((state) => state.theme.colourPaletteName);

  return (
    <Select
      size="small"
      color="secondary"
      displayEmpty
      value={colourPaletteName}
      onChange={(e) => dispatch(themeActions.setColourPaletteName(e.target.value as ColourPaletteName))}
      sx={{
        color: "currentcolor",
        [`.${selectClasses.icon}`]: {
          color: "currentcolor"
        },
        [`.${outlinedInputClasses.input}`]: {
          color: "currentcolor"
        },
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "currentcolor"
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "currentcolor"
        }
      }}
    >
      <MenuItem disabled value="">
        <em>Select palette</em>
      </MenuItem>
      {Object.values(ColourPaletteName).map((name) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};
