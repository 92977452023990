import { FunctionComponent } from "react";
import { Table, TableBody, TableRow, Collapse, Stack, Typography } from "@mui/material";

import { useAflRoundPlayerStats } from "../../hooks/useAflRoundPlayerStats";
import { usePlayerDetails } from "../../hooks/usePlayerDetails";
import { UfflStartingPosition } from "../../models/uffl";
import { useAppSelector } from "../../redux/hooks";
import { UfflStat } from "../../scoring/models";
import { useUfflRoundPlayerStats } from "../../scoring/useScore";
import { scoringRulesSelectors } from "../../slices/scoringRulesSlice";

import { Cell, StatDetailText, StatDetailValue } from "./MatchResultsTableStyles";
import { useRoundSelection } from "./RoundSelectionContext";

interface StatDetailItemProps {
  playerId: string | undefined;
  stat: UfflStat;
}

const StatDetailItem: FunctionComponent<StatDetailItemProps> = ({ playerId, stat }) => {
  const { compSeasonId, roundId } = useRoundSelection();
  const { selectStatById } = useAflRoundPlayerStats(compSeasonId, roundId);
  const count = selectStatById(playerId, stat) ?? 0;
  return (
    <Stack textAlign="center">
      <StatDetailValue>{count}</StatDetailValue>
      <StatDetailText>{stat}</StatDetailText>
    </Stack>
  );
};

interface PlayerNameCellProps {
  playerId: string | undefined;
}

const PlayerNameCell: FunctionComponent<PlayerNameCellProps> = ({ playerId }) => {
  const { compSeasonId } = useRoundSelection();
  const { selectById } = usePlayerDetails(compSeasonId);
  return (
    <Cell>
      <Typography variant="caption">{selectById(playerId)?.displayName ?? "Unknown"}</Typography>
    </Cell>
  );
};

interface PositionResultDisplayItemProps {
  userId: string;
  position: UfflStartingPosition;
}

const PositionResultDisplayItem: FunctionComponent<PositionResultDisplayItemProps> = ({ userId, position }) => {
  const { compSeasonId, roundId } = useRoundSelection();
  const weights = useAppSelector((state) => scoringRulesSelectors.selectByPosition(state, compSeasonId, position));

  const { selectScoringPlayerId } = useUfflRoundPlayerStats(compSeasonId, roundId);
  const scoringPlayerId = selectScoringPlayerId(userId, position);

  return (
    <TableRow>
      <Cell>
        <Typography variant="caption" sx={(theme) => ({ fontWeight: 600, color: theme.palette.text.secondary })}>
          {position}
        </Typography>
      </Cell>
      <PlayerNameCell playerId={scoringPlayerId} />
      <Cell>
        <Stack direction="row" justifyContent="space-around">
          {weights &&
            Object.keys(weights).map((stat) => (
              <StatDetailItem playerId={scoringPlayerId} stat={stat as UfflStat} key={stat} />
            ))}
        </Stack>
      </Cell>
    </TableRow>
  );
};

interface MatchResultsTableRowProps {
  userId: string;
  open: boolean;
  toggleOpen: () => void;
  columns: string[]; // todo: no?
  statColumns: UfflStartingPosition[]; // todo: no?
}

export const MatchResultsTableRowDetails: FunctionComponent<MatchResultsTableRowProps> = ({
  userId,
  open,
  toggleOpen,
  columns,
  statColumns
}) => {
  return (
    <TableRow onClick={toggleOpen}>
      <Cell style={{ borderBottom: "unset", paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              {statColumns.map((position) => (
                <PositionResultDisplayItem userId={userId} position={position} key={position} />
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </Cell>
    </TableRow>
  );
};
