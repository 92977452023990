import { FunctionComponent } from "react";
import { Box, Button, Stack, Typography, ButtonProps, PaletteColor } from "@mui/material";

type ColourProps = Required<Pick<ButtonProps, "color">>;

interface ColourChipProps extends ColourProps {
  token: Exclude<keyof PaletteColor, "contrastText">;
}

const ColourChip: FunctionComponent<ColourChipProps> = ({ color, token }) => {
  return (
    <Stack sx={{ alignItems: "center" }}>
      <Typography variant="body2">{token}</Typography>
      <Box sx={{ bgcolor: `${color}.${token}`, width: "2.5em", height: "1.25em" }} />
    </Stack>
  );
};

const ColourSample: FunctionComponent<ColourProps> = ({ color }) => {
  return (
    <Stack sx={{ gap: 2, alignItems: "center" }}>
      <Button variant="contained" color={color}>
        {color}
      </Button>
      <Stack direction="row" sx={{ gap: 1 }}>
        <ColourChip color={color} token="light" />
        <ColourChip color={color} token="main" />
        <ColourChip color={color} token="dark" />
      </Stack>
    </Stack>
  );
};

export const Colours: FunctionComponent = () => {
  return (
    <>
      <ColourSample color="primary" />
      <ColourSample color="secondary" />
      <ColourSample color="success" />
      <ColourSample color="error" />
      <ColourSample color="warning" />
      <ColourSample color="info" />
    </>
  );
};
