import { TypedUseQuery, TypedUseQueryStateResult } from "@reduxjs/toolkit/query/react";
import { EntityId, EntitySelectors, Selector } from "@reduxjs/toolkit";
// import type { GetStateFromSelectors as _GetStateFromSelectors } from "reselect";

import { First, Last } from "../utils/utilityTypes";

export type { GetStateFromSelectors } from "reselect";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type InferRootState<T> = T extends Selector<infer State, infer Result, infer Params> ? State : never;

// export type InferSelectFromResult<T> = T extends TypedUseQuery<infer ResultType, infer QueryArg, infer BaseQuery>
//   ? TypedUseQueryHookResult<ResultType, QueryArg, BaseQuery>
//   : never;

export type InferSelectFromResult<T> = T extends TypedUseQuery<infer ResultType, infer QueryArg, infer BaseQuery>
  ? TypedUseQueryStateResult<ResultType, QueryArg, BaseQuery>
  : never;

export const selectByIdFactory =
  <T, V, IdType extends EntityId>(selectByKnownId: EntitySelectors<T, V, IdType>["selectById"]) =>
  (
    state: First<Parameters<typeof selectByKnownId>>,
    id: Last<Parameters<typeof selectByKnownId>> | null | undefined
  ) => {
    switch (id) {
      case null:
      case undefined:
        return undefined;
      default:
        return selectByKnownId(state, id);
    }
  };

export const createParametricSelector = (
  selector: Selector
): typeof selector extends Selector<infer State, infer Result, infer Params>
  ? (...params: Params) => (state: State) => Result
  : never => {
  return (...params) => {
    return (state) => selector(state, ...params);
  };
};
