import { useFirestore } from "reactfire";

import { aflRoundPlayerStatsSelectors, useGetAflRoundPlayerStatsQuery } from "../slices/aflRoundPlayerStatsApi";
import { DropFirst } from "../utils/utilityTypes";

export const useAflRoundPlayerStats = (compSeasonId: string, roundId: string) =>
  useGetAflRoundPlayerStatsQuery(
    { firestore: useFirestore(), compSeasonId, roundId },
    {
      selectFromResult: (result) => ({
        ...result,
        selectById: (...[playerId]: DropFirst<Parameters<typeof aflRoundPlayerStatsSelectors.selectById>>) =>
          aflRoundPlayerStatsSelectors.selectById(result, playerId),
        selectStatById: (
          ...[playerId, stat]: DropFirst<Parameters<typeof aflRoundPlayerStatsSelectors.selectStatById>>
        ) => aflRoundPlayerStatsSelectors.selectStatById(result, playerId, stat),
        selectStatsById: (
          ...[playerId, stats]: DropFirst<Parameters<typeof aflRoundPlayerStatsSelectors.selectStatsById>>
        ) => aflRoundPlayerStatsSelectors.selectStatsById(result, playerId, stats)
      })
    }
  );
