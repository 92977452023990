import { AflMatchScore } from "../models/afl";
import { PlayerDisplayItem, PlayerStat } from "../models/player";

import { UfflStartingPosition } from "../models/uffl";
import { ExtractKeys } from "../utils/utilityTypes";

export enum ScoringSeason {
  CD_S2022014 = "CD_S2022014",
  CD_S2023014 = "CD_S2023014",
  CD_S2024014 = "CD_S2024014",
  CD_S2025014 = "CD_S2025014"
}

export type UfflStat = ExtractKeys<
  PlayerStat,
  | "behinds"
  | "totalClearances"
  | "disposals"
  | "freesAgainst"
  | "goals"
  | "handballs"
  | "inside50s"
  | "kicks"
  | "marks"
  | "marksInside50" // TODO: 2023 scoring change
  | "onePercenters"
  | "rebound50s"
  | "tackles"
  | "tacklesInside50"
>;

export type PositionWeighting = Partial<Record<UfflStat, number>>;

export type PositionWeightedStats = Partial<Record<UfflStartingPosition, PositionWeighting>>;

export interface ScoringRuleset {
  position: PositionWeightedStats;
  club?: { wins?: number };
}

export interface UfflStatResult {
  count: number;
  weight: number;
  value: number;
}

export type UfflPositionStatsResult<T extends UfflStartingPosition> = {
  [S in keyof PositionWeightedStats[T]]: UfflStatResult;
};

export type UfflPositionResult<T extends UfflStartingPosition> = {
  player: PlayerDisplayItem;
  stats: UfflPositionStatsResult<T>;
  total: number;
};

export type UfflLineupResult = {
  [P in UfflStartingPosition]: UfflPositionResult<P>;
};

export type AflClubResult = {
  clubId: string;
  clubName: string;
  value: number;
  score?: AflMatchScore;
};

export type UfflTeamResult = {
  teamName: string;
  total: number;
  lineup: UfflLineupResult;
  // club: AflClubResult; // TODO: 2023 scoring change
};
