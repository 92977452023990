import { useFirestore } from "reactfire";

import { ufflUsersSelectors, useGetUfflUsersQuery } from "../slices/ufflUsersApi";
import { Last } from "../utils/utilityTypes";

export const useUfflUsers = () =>
  useGetUfflUsersQuery(useFirestore(), {
    selectFromResult: (result) => ({
      ...result,
      selectAll: ufflUsersSelectors.selectAll(result),
      selectById: (id: Last<Parameters<typeof ufflUsersSelectors.selectById>>) =>
        ufflUsersSelectors.selectById(result, id)
    })
  });
